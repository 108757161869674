import React from "react";
import Mission1N1 from "./../images/Mission1/1.png";
import Mission1N2 from "./../images/Mission1/2.png";
import Mission1N3 from "./../images/Mission1/3.png";
import Mission1N4 from "./../images/Mission1/4.png";
import Mission1N5 from "./../images/Mission1/5.png";
import Mission1N6 from "./../images/Mission1/6.png";
import Mission2N1 from "./../images/Mission2/1.png";
import Mission2N2 from "./../images/Mission2/2.png";
import Mission2N3 from "./../images/Mission2/3.png";
import Mission2N4 from "./../images/Mission2/4.png";
import Mission2N5 from "./../images/Mission2/5.png";
import Mission2N6 from "./../images/Mission2/6.png";
import Mission3N1 from "./../images/Mission3/1.png";
import Mission3N2 from "./../images/Mission3/2.png";
import Mission3N3 from "./../images/Mission3/3.png";
import Mission3N4 from "./../images/Mission3/4.png";
import Mission3N5 from "./../images/Mission3/5.png";
import Mission3N6 from "./../images/Mission3/6.png";
import Mission3Glace1 from "./../images/Mission3/glace1.png";
import Mission3Glace2 from "./../images/Mission3/glace2.png";
import Mission3Glace3 from "./../images/Mission3/glace3.png";
import Mission3Zombie1 from "./../images/Mission3/zombie1.png";
import Mission3Zombie2 from "./../images/Mission3/zombie2.png";
import Mission3Zombie3 from "./../images/Mission3/zombie3.png";
import Mission3Paque1 from "./../images/Mission3/paque1.png";
import Mission3Paque2 from "./../images/Mission3/paque2.png";
import Mission3Paque3 from "./../images/Mission3/paque3.png";
import Mission3Nuclaire1 from "./../images/Mission3/nuclaire1.png";
import Mission3Nuclaire2 from "./../images/Mission3/nuclaire2.png";
import Mission3Nuclaire3 from "./../images/Mission3/nuclaire3.png";
import Mission3Winter1 from "./../images/Mission3/winter1.png";
import Mission3Winter2 from "./../images/Mission3/winter2.png";
import Mission3Winter3 from "./../images/Mission3/winter3.png";
import Mission3Halloween1 from "./../images/Mission3/halloween1.png";
import Mission3Halloween2 from "./../images/Mission3/halloween2.png";
import Mission3Halloween3 from "./../images/Mission3/halloween3.png";
export const Mission = ({ niveau, numero }) => {
  if (niveau === 1) {
    switch (numero) {
      case 1:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N1}
            alt="Mission"
          />
        );
      case 2:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N2}
            alt="Mission"
          />
        );
      case 3:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N3}
            alt="Mission"
          />
        );
      case 4:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N4}
            alt="Mission"
          />
        );
      case 5:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N5}
            alt="Mission"
          />
        );
      case 6:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission1N6}
            alt="Mission"
          />
        );
      default:
        return null;
    }
  }
  if (niveau === 2) {
    switch (numero) {
      case 1:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N1}
            alt="Mission"
          />
        );
      case 2:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N2}
            alt="Mission"
          />
        );
      case 3:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N3}
            alt="Mission"
          />
        );
      case 4:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N4}
            alt="Mission"
          />
        );
      case 5:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N5}
            alt="Mission"
          />
        );
      case 6:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission2N6}
            alt="Mission"
          />
        );
      default:
        return null;
    }
  }
  if (niveau === 3) {
    switch (numero) {
      case 1:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N1}
            alt="Mission"
          />
        );
      case 2:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N2}
            alt="Mission"
          />
        );
      case 3:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N3}
            alt="Mission"
          />
        );
      case 4:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N4}
            alt="Mission"
          />
        );
      case 5:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N5}
            alt="Mission"
          />
        );
      case 6:
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3N6}
            alt="Mission"
          />
        );
      case "glace1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Glace1}
            alt="Mission"
          />
        );
      case "glace2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Glace2}
            alt="Mission"
          />
        );
      case "glace3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Glace3}
            alt="Mission"
          />
        );
      case "zombie1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Zombie1}
            alt="Mission"
          />
        );
      case "zombie2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Zombie2}
            alt="Mission"
          />
        );
      case "zombie3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Zombie3}
            alt="Mission"
          />
        );
      case "paque1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Paque1}
            alt="Mission"
          />
        );
      case "paque2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Paque2}
            alt="Mission"
          />
        );
      case "paque3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Paque3}
            alt="Mission"
          />
        );
      case "nucleaire1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Nuclaire1}
            alt="Mission"
          />
        );
      case "nucleaire2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Nuclaire2}
            alt="Mission"
          />
        );
      case "nucleaire3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Nuclaire3}
            alt="Mission"
          />
        );
      case "winter1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Winter1}
            alt="Mission"
          />
        );
      case "winter2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Winter2}
            alt="Mission"
          />
        );
      case "winter3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Winter3}
            alt="Mission"
          />
        );
      case "halloween1":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Halloween1}
            alt="Mission"
          />
        );
      case "halloween2":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Halloween2}
            alt="Mission"
          />
        );
      case "halloween3":
        return (
          <img
            className="mission"
            width="100%"
            src={Mission3Halloween3}
            alt="Mission"
          />
        );
      default:
        return null;
    }
  }
};

import React from "react";
import Piscine from "./../images/piscine.png";
import Forets from "./../images/forets.png";
import Barriere from "./../images/barriere.png";
import Bis from "./../images/bis.png";
import Traveaux from "./../images/traveau.png";
import Bourse from "./../images/bourse.jpg";
import Porte from "./../images/porte.jpg";
import ZombieM from "./../images/zombieM.jpg";
import ZombieL from "./../images/zombieL.jpg";
import ZombieR from "./../images/zombieR.jpg";
import MiniPiscine from "./../images/mini-piscine.png";
import MiniForets from "./../images/mini-forets.png";
import MiniBarriere from "./../images/mini-barriere.png";
import MiniBis from "./../images/mini-bis.png";
import MiniTraveaux from "./../images/mini-traveau.png";
import MiniBourse from "./../images/mini-bourse.png";

export const CarteAction = ({ action }) => {
  switch (action) {
    case "bis":
      return <img width="100%" alt={action} src={Bis} />;
    case "piscine":
      return <img width="100%" alt={action} src={Piscine} />;
    case "forets":
      return <img width="100%" alt={action} src={Forets} />;
    case "barriere":
      return <img width="100%" alt={action} src={Barriere} />;
    case "traveaux":
      return <img width="100%" alt={action} src={Traveaux} />;
    case "bourse":
      return <img width="100%" alt={action} src={Bourse} />;
    default:
      return <div>{action}</div>;
  }
};

export const MiniAction = ({ action }) => {
  switch (action) {
    case "bis":
      return <img width="100%" alt={action} src={MiniBis} />;
    case "piscine":
      return <img width="100%" alt={action} src={MiniPiscine} />;
    case "forets":
      return <img width="100%" alt={action} src={MiniForets} />;
    case "barriere":
      return <img width="100%" alt={action} src={MiniBarriere} />;
    case "traveaux":
      return <img width="100%" alt={action} src={MiniTraveaux} />;
    case "bourse":
      return <img width="100%" alt={action} src={MiniBourse} />;
    default:
      return <></>;
  }
};

export const CartePorte = ({ numero, action }) => {
  if (typeof numero === "number") {
    return (
      <div className="carte" Style="position:relative;">
        <div Style="position:absolute;z-index:1">
          <img alt="Portes" src={Porte} width="100%"></img>
        </div>
        <div Style="position:absolute;top:10px;left:120px; width:20px; z-index:2;color:white">
          <MiniAction action={action} />
        </div>
        <div Style="position:absolute;top:12px;left:22px; width:20px; z-index:2;color:white">
          {numero}
        </div>
        <div Style="position:absolute;top:63px;left:55px; width:20px; z-index:2;font-size:200%;">
          {numero}
        </div>
        <div Style="position:absolute;top:165px;left:108px; width:20px; z-index:2;color:white">
          {numero}
        </div>
      </div>
    );
  } else {
    if (numero === "zombieM") {
      return <img width="100%" alt={numero} src={ZombieM} />;
    }
    if (numero === "zombieL") {
      return <img width="100%" alt={numero} src={ZombieL} />;
    }
    if (numero === "zombieR") {
      return <img width="100%" alt={numero} src={ZombieR} />;
    }
  }
};

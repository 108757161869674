import "./Accueil.css";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import TextField from "@mui/material/TextField";

const Accueil = ({ socket, ENDPOINT }) => {
  const [id, setId] = useState("");
  const [modeDeJeu, setModeDeJeu] = useState("");

  return (
    <>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <div className="content">
        <h1>Welcome to</h1>
        <div className="menu">
          <select
            value={modeDeJeu}
            onChange={(e) => setModeDeJeu(e.target.value)} // ...et met à jour la variable d'état à chaque modification !
          >
            <option value="">Mode Classique</option>
            <option value="glace">Glace</option>
            <option value="zombie">Zombie</option>
            <option value="paque">Paque</option>
            <option value="nucleaire">Nucléaire</option>
            <option value="winter">Winter</option>
            <option value="halloween">Halloween</option>
          </select>
          <Button
            onClick={() => socket.emit("newParty", { modeDeJeu })}
            variant="contained"
            startIcon={<VideogameAssetIcon />}
          >
            Nouvelle partie
          </Button>
          <TextField
            id="outlined-name"
            label="Id de la partie à rejoindre"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() =>
              window.location.replace(
                "https://welcometo.allprojects.cloud/game/" + id
              )
            }
            startIcon={<MeetingRoomIcon />}
          >
            Rejoindre
          </Button>
        </div>
      </div>
    </>
  );
};
export default Accueil;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Accueil from "./Accueil.js";
import Game from "./Game/Game";
import "./App.css";
import socketIOClient from "socket.io-client";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ENDPOINT = "https://apiwelcometo.allprojects.cloud";
//const ENDPOINT = "http://195.110.59.85:5005";
const socket = socketIOClient(ENDPOINT);

const Ready = () => {
  const { id } = useParams();
  return <Game id={id} socket={socket} />;
};

const App = () => {
  useEffect(() => {
    if (typeof cookies.get("user") === "undefined") {
      let id =
        Math.random().toString(36).slice(2) +
        Math.random().toString(36).toUpperCase().slice(2);
      cookies.set("user", id, { path: "/" });
    }

    socket.on("newParty", (id) => {
      window.location.replace("https://welcometo.allprojects.cloud/game/" + id);
    });

    return () => {
      socket.removeListener("newParty");
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="*"
          element={<Accueil socket={socket} ENDPOINT={ENDPOINT}></Accueil>}
        />
        <Route path="/game/:id" element={<Ready />}></Route>
      </Routes>
    </Router>
  );
};
export default App;

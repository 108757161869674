import "./Game.css";
import React, { useEffect, useState, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Cookies from "universal-cookie";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CarteAction, CartePorte } from "./Carte";
import { Mission } from "./Mission";
import useSound from "use-sound";
import Ding from "./../son/belldoor.mp3";
const cookies = new Cookies();

const regleDujeuUrl = (mode) => {
  switch (mode) {
    case "zombie":
      return "https://cdn.1j1ju.com/medias/fe/01/a5-welcome-to-your-perfect-home-invasion-zombies-regle.pdf";
    case "halloween":
      return "https://cdn.1j1ju.com/medias/f9/0b/7c-welcome-to-your-perfect-home-halloween-regle.pdf";
    case "winter":
      return "https://cdn.1j1ju.com/medias/f2/3e/f8-welcome-to-your-perfect-home-lumieres-de-noel-regle.pdf";
    case "glace":
      return "https://cdn.1j1ju.com/medias/32/d2/b0-welcome-to-your-perfect-home-camion-de-glace-regle.pdf";
    case "paque":
      return "https://cdn.1j1ju.com/medias/82/22/2b-welcome-to-your-perfect-home-chasse-aux-oeufs-guerre-atomique-regle.pdf";
    case "nucleaire":
      return "https://cdn.1j1ju.com/medias/82/22/2b-welcome-to-your-perfect-home-chasse-aux-oeufs-guerre-atomique-regle.pdf";
    default:
      return "https://cdn.1j1ju.com/medias/09/86/d9-welcome-to-your-perfect-home-regle.pdf";
  }
};

const Game = ({ id, socket }) => {
  const [party, setParty] = useState(null);
  const previousParty = usePrevious(party);
  const [input, setInput] = useState("");
  const [play] = useSound(Ding);

  function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  }
  useEffect(() => {
    if (party && previousParty && previousParty?.index !== party?.index) {
      play();
    }
  }, [party, play, previousParty]);

  useEffect(() => {
    socket.emit("getParty", id);
    socket.on("getParty/" + id, (res) => {
      setParty(res);
    });
    socket.on("getParty", (party) => {
      setParty(party);
    });

    return () => {
      socket.removeListener("getParty/" + id);
      socket.removeListener("getParty");
    };
  }, [id, socket]);

  const renderNotExist = () => {
    if (party === null) return <p>La partie n'existe pas</p>;
  };

  const renderGameBegin = () => {
    if (party !== null && party.isStart)
      return (
        <div className="jeu">
          <div Style={"margin-right:20px"}>
            <div className="row">
              <div
                Style={"width:160px;position:relative"}
                onClick={() =>
                  socket.emit("missionOK", { idParty: id, missionValider: 1 })
                }
              >
                <Mission niveau={1} numero={party.mission1}></Mission>
                <div Style="position: absolute;bottom: 05px;left: 22px;font-size: 40px;">
                  {party.mission1OK && "🟢"}
                </div>
              </div>
              <div
                Style={"width:160px;position:relative"}
                onClick={() =>
                  socket.emit("missionOK", { idParty: id, missionValider: 2 })
                }
              >
                <Mission niveau={2} numero={party.mission2}></Mission>
                <div Style="position: absolute;bottom: 05px;left: 22px;font-size: 40px;">
                  {party.mission2OK && "🟢"}
                </div>
              </div>
              <div
                Style={"width:160px;position:relative"}
                onClick={() =>
                  socket.emit("missionOK", { idParty: id, missionValider: 3 })
                }
              >
                <Mission niveau={3} numero={party.mission3}></Mission>
                <div Style="position: absolute;bottom: 05px;left: 22px;font-size: 40px;">
                  {party.mission3OK && "🟢"}
                </div>
              </div>
            </div>
            <div>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>Joueur</th>
                    <th>Prêt</th>
                  </tr>
                </thead>
                <tbody>
                  {party?.users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>
                        {user.id === cookies.get("user") && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              socket.emit("userPlayParty", {
                                idParty: id,
                                idUser: cookies.get("user"),
                              });
                            }}
                          >
                            {!user.ready ? "Prêt à retourner" : "Annuler"}
                          </Button>
                        )}

                        {user.id !== cookies.get("user") &&
                          user.ready &&
                          "Prêt à retourner"}
                        {user.id !== cookies.get("user") &&
                          !user.ready &&
                          "En train de jouer"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            Tours joués : {party.nbTour + 1}
            <br />
            <a
              target="_blank"
              href={regleDujeuUrl(party.modeDeJeu)}
              rel="noreferrer"
            >
              Régles
            </a>
            <br />
            ID de la partie en cours : {id}
            <br />
            <Button
              variant="contained"
              onClick={() => {
                window.location.replace("https://welcometo.allprojects.cloud/");
              }}
            >
              Revenir à l'accueil
            </Button>
          </div>
          <div>
            <div className="row">
              <div Style={"width:170px;"}>
                <CartePorte
                  numero={party.numbers[party.index]}
                  action={party.actions[party.index + 1]}
                />
              </div>
              <div Style={"width:170px"}>
                <CarteAction action={party.actions[party.index]} />
              </div>
            </div>
            <div className="row">
              <div Style={"width:170px"}>
                <CartePorte
                  numero={party.numbers[party.index + 27]}
                  action={party.actions[party.index + 28]}
                />
              </div>
              <div Style={"width:170px"}>
                <CarteAction action={party.actions[party.index + 27]} />
              </div>
            </div>
            <div className="row">
              <div Style={"width:170px"}>
                <CartePorte
                  numero={party.numbers[party.index + 27 + 27]}
                  action={party.actions[party.index + 28 + 27]}
                />
              </div>
              <div Style={"width:170px"}>
                <CarteAction action={party.actions[party.index + 27 + 27]} />
              </div>
            </div>
          </div>
        </div>
      );
  };

  const renderNotBegin = () => {
    if (party !== null && !party.isStart) {
      let users = [];

      let boolFind = false;
      let boolReady = false;
      party.users.forEach((element, index) => {
        if (element.id === cookies.get("user")) {
          boolFind = true;
          boolReady = element.ready;
        }
        users.push(
          <TextField
            label={"Joueur " + (index + 1)}
            value={element.name}
            disabled={true}
            InputProps={{
              startAdornment: <AccountCircle />,
              endAdornment: element.ready ? (
                <CheckCircleRoundedIcon></CheckCircleRoundedIcon>
              ) : (
                <CloseRoundedIcon></CloseRoundedIcon>
              ),
            }}
            variant="standard"
          />
        );
      });

      return (
        <>
          <>
            Identifiant de la partie : {id}
            <br />
          </>
          <div className="currentPlayer">{users}</div>
          {boolFind ? (
            <>
              <Button
                variant="contained"
                onClick={() =>
                  socket.emit("deleteUserParty", {
                    idUser: cookies.get("user"),
                    idParty: id,
                  })
                }
              >
                Se désinscrire
              </Button>
              {!boolReady ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() =>
                    socket.emit("userReadyParty", {
                      idUser: cookies.get("user"),
                      idParty: id,
                    })
                  }
                >
                  Ready ?
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    socket.emit("userReadyParty", {
                      idUser: cookies.get("user"),
                      idParty: id,
                    })
                  }
                >
                  Not ready ?
                </Button>
              )}
            </>
          ) : (
            <>
              <TextField
                label={"Votre nom"}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <Button
                variant="contained"
                onClick={() =>
                  socket.emit("newUserParty", {
                    idUser: cookies.get("user"),
                    name: input,
                    idParty: id,
                  })
                }
              >
                S'inscrire
              </Button>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <div className="content">
        <h1>Welcome to</h1>
        <div className="menu">
          {renderNotExist()}
          {renderNotBegin()}
          {renderGameBegin()}
        </div>
      </div>
    </>
  );
};
export default Game;
